.appRoot {
  //   position: relative; // Ensures the background is positioned relative to this container
  // Other styles you might have
  height: 100vh;
  width: 100vw;
  background: #fbfbfb;
  .appBar {
    background: #fff;
    color: black;
    font-weight: 700;
    .toolbar {
      padding: 4px 84px;
      .logo {
        height: 48px;
      }
      .text {
        font-size: 30px;
        font-weight: 700;
        background: linear-gradient(to right, #4b0082, #4b0082);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
      }
      .right {
        margin-left: auto;
        display: flex;
        gap: 8px;
        button,
        a {
          text-transform: none;
          font-weight: 500;
          font-size: 16px;
          padding: 6px 24px;
          border-radius: 8px;
          &:hover {
            opacity: 0.8;
          }
        }
        .primary {
          background: #4b0082;
          color: #fff;
        }
        .text {
          font-size: 16px;
          color: #000;
        }
      }
    }
  }
  .center {
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: normal;
    gap: 24px;
    padding-top: 10rem;
    .background {
      width: 30vh;
      height: 30vh;
      border-radius: 50%;
      -webkit-filter: blur(3px);
      -moz-filter: blur(3px);
      -o-filter: blur(3px);
      -ms-filter: blur(3px);
      filter: blur(3px);
      z-index: -1; // Ensures the background is behind other content
      canvas {
        border-radius: 50%;
      }
    }
    .tile {
      margin: 0px 64px;
      display: flex;
      text-transform: none;
      color: #000;
      flex-direction: row;
      gap: 8px;
      align-items: center;
      justify-content: center;
      border: 1px solid #ccc;
      border-radius: 32px;
      padding: 8px 16px;
      //   background: linear-gradient(to right, #4b0082, #ff5660);
      background: #f7f3fe;
      cursor: pointer;
      div {
        font-size: 14px;
        font-weight: 700;
      }
      svg {
        font-size: 10px;
        color: green;
      }
      &:hover {
        background: #f7f3fe;
      }
    }
    .titleWrapper {
      display: flex;
      flex-direction: column;
      font-size: 64px;
      font-weight: 700;
      .gradientText {
        background: linear-gradient(90deg, #4b0082 0%, #8544b4 50%, #ff5660 100%);

        // background: linear-gradient(
        //     217deg,
        //     rgb(255 0 0 / 80%),
        //     rgb(255 0 0 / 0%) 70.71%
        //   ),
        //   linear-gradient(127deg, rgb(0 255 0 / 80%), rgb(0 255 0 / 0%) 70.71%),
        //   linear-gradient(336deg, rgb(0 0 255 / 80%), rgb(0 0 255 / 0%) 70.71%);

        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
      }
    }
    .subtitle {
      font-size: 18px;
      font-weight: 500;
      padding: 0px 12px;
      color: #666;
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
    .buttons {
      margin-top: 24px;
      display: flex;
      flex-direction: row;
      gap: 32px;
      justify-content: center;
      button,
      a {
        text-transform: none;
        font-weight: 500;
        font-size: 18px;
        padding: 6px 24px;
        border-radius: 8px;
        box-shadow: 0px 0px 12px 0px rgba(75, 0, 130, 0.6);
        &:hover {
          opacity: 0.8;
        }
      }
      .primary {
        background: #4b0082;
        color: #fff;
      }
      .secondary {
        border: 1px solid #4b0082;
        color: #4b0082;
      }
    }
  }
  .wrapper {
    position: relative;
    .agents {
      // margin-top: 64px;
      display: flex;
      gap: 12px;
      padding: 32px 84px;
      .agent {
        flex: 1;
        background: #f7f3fe;
        border: 1px solid #4b0082;
        box-shadow: 0px 0px 6px 0px rgba(75, 0, 130, 0.6);
        border-radius: 16px;
        padding: 0px 32px 32px 32px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        text-align: center;
        .icon {
          margin-top: -30px;
          img {
            border-radius: 128px;
          }
        }
        .agentTitle {
          font-size: 24px;
          font-weight: 700;
        }
        .agentDescription {
          font-size: 18px;
          font-weight: 500;
          // color: #666;
        }
        .agentFeatures {
          display: flex;
          flex-direction: column;
          gap: 8px;
          font-size: 14px;
          font-weight: 500;
          color: #666;
          text-align: left;
          padding: 0px 16px;
          svg {
            font-size: 8px;
            color: #222;
            margin-top: 6px;
          }
          .agentFeature {
            display: flex;
            gap: 8px;
          }
        }
        a,
        button {
          margin: auto;
          text-transform: none;
          font-weight: 700;
          font-size: 14px;
          padding: 6px 16px;
          border-radius: 8px;
          &:hover {
            opacity: 0.8;
          }
        }
        .primary {
          width: fit-content;
          background: #4b0082;
          color: #fff;
        }
      }
    }
    .blur {
      -webkit-filter: blur(8px);
      -moz-filter: blur(8px);
      -o-filter: blur(8px);
      -ms-filter: blur(8px);
      filter: blur(8px);
    }
    .comingSoon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 36px;
      color: #4b0082;
      font-weight: 700;
      background: linear-gradient(90deg, #4b0082 0%, #8544b4 50%, #ff5660 100%);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
    }
  }
  .footer {
    margin-top: 32px;
    .divider {
      .circular {
        color: #4b0082;
        font-size: 24px;
        font-weight: 700;
        background: linear-gradient(
          to bottom,
          #4b0082,
          #9a56ff 50%,
          #4b0082 100%
        );
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
      }
    }
    .footerText {
      padding: 32px 84px;
      font-size: 14px;
      font-weight: 500;
      color: #666;
      opacity: 0.5;
    }
  }
}

@media (max-width: 768px) {
  .appRoot .appBar .toolbar {
    padding: 4px 20px; /* Adjust padding for mobile */
  }

  .appRoot .appBar .toolbar .logo {
    height: 32px; /* Adjust height for mobile */
  }

  .appRoot .center .tile {
    margin: 0px;
  }

  .appRoot .appBar .toolbar .text,
  .appRoot .center .titleWrapper {
    font-size: 48px; /* Adjust font size for mobile */
  }

  .appRoot .appBar .toolbar .right button,
  .appRoot .appBar .toolbar .right a,
  .appRoot .center .buttons button,
  .appRoot .center .buttons a {
    font-size: 14px; /* Adjust font size for mobile */
    padding: 6px 12px; /* Adjust padding for mobile */
  }

  .appRoot .center {
    padding-top: 5rem; /* Adjust padding top for mobile */
  }

  .appRoot .wrapper .agents {
    flex-direction: column; /* Switch to column layout */
    gap: 32px; /* Adjust gap for mobile */
    margin: 0 8px; /* Adjust margin for mobile */
    padding: 16px 8px;
  }

  .appRoot .wrapper .agents .agent {
    padding: 0 16px 16px; /* Adjust padding for mobile */
  }

  .appRoot .wrapper .comingSoon {
    font-size: 24px; /* Adjust font size for mobile */
    text-align: center;
  }

  .appRoot .footer .footerText {
    padding: 32px 20px; /* Adjust padding for mobile */
  }
}
