.root {
  background: #fbfbfb;
  .appBar {
    background: #fff;
    color: black;
    font-weight: 700;
    .toolbar {
      padding: 4px 84px;
      .logo {
        height: 48px;
      }
      .text {
        font-size: 30px;
        font-weight: 700;
        background: linear-gradient(to right, #4b0082, #4b0082);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
      }
      .right {
        margin-left: auto;
        display: flex;
        gap: 8px;
        button,
        a {
          text-transform: none;
          font-weight: 500;
          font-size: 16px;
          padding: 6px 20px;
          border-radius: 8px;
          &:hover {
            opacity: 0.8;
          }
        }
        .primary {
          background: #4b0082;
          color: #fff;
        }
        .text {
          font-size: 16px;
          color: #000;
        }
      }
    }
  }
}
