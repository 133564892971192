.container{
  margin: 100px 128px 80px 128px;
  a{
    color: #590895;
  }
}

@media (max-width: 1000px) {
  .container {
    margin: 80px 50px 40px 50px;
  }
}