.footerLight {
  padding: 64px 128px 72px 128px;
  .content {
    margin-top: 44px;
    display: flex;
    .left {
      display: flex;
      flex-direction: column;
      .copyright{
        margin-top: 32px;
      }
      .contact{
        margin-top: 4px;
        a{
          color: #590895;
        }
      }
    }
    .right {
      margin-left: auto;
      display: flex;
      flex-direction: row;
      gap: 64px;
      .links {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .header {
          color: #252525;
          font-size: 24px;
          font-weight: 500;
        }
        .text {
          display: flex;
          gap: 8px;
          align-items: center;
          text-decoration: none;
          color: inherit;
          img {
            width: 20px;
          }
          position: relative;
        }
        .text::before {
          content: "";
          position: absolute;
          left: 0;
          bottom: -2px;
          width: 0;
          height: 1px;
          background-color: #252525;
          transition: width 0.5s ease-in-out;
        }
        .text:hover {
          color: #252525;
        }
        .text:hover::before {
          width: 100%;
        }
      }
    }
  }
}

.footerDark {
  padding: 64px 128px 72px 128px;
  .content {
    margin-top: 44px;
    display: flex;
    .left {
      display: flex;
      flex-direction: column;
      .copyright{
        margin-top: 32px;
      }
      .contact{
        margin-top: 4px;
        a{
          color: #590895;
        }
      }
    }
    .right {
      margin-left: auto;
      display: flex;
      flex-direction: row;
      gap: 64px;
      .links {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .header {
          color: #fff;
          font-size: 24px;
          font-weight: 500;
        }
        .text {
          display: flex;
          gap: 8px;
          align-items: center;
          text-decoration: none;
          color: inherit;
          img {
            width: 20px;
          }
          position: relative;
        }
        .text::before {
          content: "";
          position: absolute;
          left: 0;
          bottom: -2px;
          width: 0;
          height: 1px;
          background-color: #fff;
          transition: width 0.5s ease-in-out;
        }
        .text:hover {
          color: #fff;
        }
        .text:hover::before {
          width: 100%;
        }
      }
    }
  }
}

.footerLightSmall {
  padding: 32px;
  .content {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 62px;
    .left {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-left: auto;
      margin-right: auto;
      align-items: center;
    }
    .right {
      display: flex;
      flex-direction: row;
      gap: 64px;
      margin-left: auto;
      margin-right: auto;
      .links {
        display: flex;
        flex-direction: column;
        gap: 12px;
        .header {
          color: #252525;
          font-size: 20px;
          font-weight: 900;
        }
        .text {
          display: flex;
          gap: 8px;
          align-items: center;
          text-decoration: none;
          color: inherit;
          img {
            width: 20px;
          }
        }
      }
    }
  }
}

.footerDarkSmall {
  padding: 32px;
  .content {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 62px;
    .left {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-left: auto;
      margin-right: auto;
      align-items: center;
    }
    .right {
      display: flex;
      flex-direction: row;
      gap: 64px;
      margin-left: auto;
      margin-right: auto;
      .links {
        display: flex;
        flex-direction: column;
        gap: 12px;
        .header {
          color: #fff;
          font-size: 20px;
          font-weight: 900;
        }
        .text {
          display: flex;
          gap: 8px;
          align-items: center;
          text-decoration: none;
          color: inherit;
          img {
            width: 20px;
          }
        }
      }
    }
  }
}
