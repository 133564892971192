.root {
  height: 100vh;
  .pricingLight {
    padding: 64px 128px;
    display: flex;
    gap: 44px;
    flex-direction: column;
    .title {
      font-size: 52px;
      font-weight: 900;
    }
    .description {
      font-size: 20px;
      font-weight: 500;
    }
    .tiles {
      display: flex;
      gap: 36px;
      text-align: center;
      .tile {
        padding: 32px;
        border-radius: 16px;
        flex: 1;
        border: 1px solid rgba(17, 24, 39, 0.12);
        background: linear-gradient(180deg, #e3bcff 0%, #fff 100%);
        display: flex;
        flex-direction: column;
        gap: 24px;
        &:hover {
          box-shadow: 0px 0px 8px 0px rgba(161, 103, 205, 0.7);
        }
        .popular {
          margin: -48px auto;
          border-radius: 8px;
          background: var(
            --text-gradient,
            linear-gradient(180deg, #8544b4 0%, #4b0082 100%)
          );
          color: #fff;
          font-size: 12px;
          font-weight: 500;
          padding: 8px;
          width: 100px;
        }
        .title {
          font-size: 18px;
          font-weight: 500;
        }
        .description {
          font-size: 12px;
        }
        .pricing {
          font-size: 44px;
          font-weight: 700;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
          .text {
            color: rgba(51, 51, 51, 0.7);
            font-size: 12px;
            font-weight: 500;
            text-align: left;
          }
        }
        .cta {
          border-radius: 8px;
          text-transform: none;
          font-size: 16px;
          background: #590895;
          font-weight: 700;
          padding: 8px 24px;
          box-shadow: 0px 0px 12px 0px rgba(75, 0, 130, 0.6);
          transition: box-shadow 0.3s;
          &:hover {
            background: linear-gradient(180deg, #8544b4 0%, #4b0082 100%);
            box-shadow: 0px 0px 8px 0px rgba(75, 0, 130, 0.9);
          }
        }
        .points {
          display: flex;
          flex-direction: column;
          text-align: left;
          gap: 20px;
          .point {
            display: flex;
            gap: 8px;
            font-size: 14px;
            align-items: center;
            .check {
              font-size: 18px;
              color: #4b0082;
            }
          }
        }
      }
      .active {
        box-shadow: 0px 0px 8px 0px rgba(161, 103, 205, 0.7);
        border: 1px solid #4b0082;
        .title {
          margin-top: 36px;
        }
      }
    }
  }
}

.pricingDark {
  padding: 64px 128px;
  display: flex;
  gap: 44px;
  flex-direction: column;
  .title {
    font-size: 52px;
    font-weight: 900;
  }
  .description {
    font-size: 20px;
    font-weight: 500;
  }
  .tiles {
    display: flex;
    gap: 36px;
    text-align: center;
    .tile {
      padding: 32px;
      border-radius: 16px;
      flex: 1;
      border: 1px solid rgba(17, 24, 39, 0.12);
      background: linear-gradient(180deg, #1d0033 0%, rgba(24, 0, 42, 0) 100%);
      display: flex;
      flex-direction: column;
      gap: 24px;
      &:hover {
        box-shadow: 0px 0px 8px 0px rgba(75, 0, 130, 0.9);
      }
      .popular {
        margin: -48px auto;
        border-radius: 8px;
        background: var(
          --text-gradient,
          linear-gradient(180deg, #8544b4 0%, #4b0082 100%)
        );
        color: #fff;
        font-size: 12px;
        font-weight: 500;
        padding: 8px;
        width: 100px;
      }
      .title {
        font-size: 18px;
        font-weight: 500;
      }
      .description {
        font-size: 12px;
      }
      .pricing {
        font-size: 44px;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        .text {
          color: #fff;
          font-size: 12px;
          font-weight: 500;
          text-align: left;
        }
      }
      .cta {
        border-radius: 8px;
        text-transform: none;
        font-size: 16px;
        color: #fff;
        background: #590895;
        font-weight: 700;
        padding: 8px 24px;
        box-shadow: 0px 0px 12px 0px rgba(75, 0, 130, 0.6);
        transition: box-shadow 0.3s;
        &:hover {
          background: linear-gradient(180deg, #8544b4 0%, #4b0082 100%);
          box-shadow: 0px 0px 8px 0px rgba(75, 0, 130, 0.9);
        }
      }
      .points {
        display: flex;
        flex-direction: column;
        text-align: left;
        gap: 20px;
        .point {
          display: flex;
          gap: 8px;
          font-size: 14px;
          align-items: center;
          .check {
            font-size: 18px;
            color: #4b0082;
          }
        }
      }
    }
    .active {
      box-shadow: 0px 0px 8px 0px rgba(161, 103, 205, 0.7);
      border: 1px solid #4b0082;
      .title {
        margin-top: 36px;
      }
    }
  }
}

.pricingLightSmall {
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .title {
    font-size: 32px;
    font-weight: 900;
  }
  .description {
    font-size: 14px;
    font-weight: 500;
  }
  .tiles {
    display: flex;
    gap: 16px;
    flex-direction: column;
    text-align: center;
    .tile {
      padding: 24px;
      border-radius: 12px;
      background: linear-gradient(180deg, #e3bcff 0%, #fff 100%);
      display: flex;
      flex-direction: column;
      gap: 16px;
      &:hover {
        box-shadow: 0px 0px 8px 0px rgba(161, 103, 205, 0.7);
      }
      .popular {
        margin: -38px auto auto auto;
        border-radius: 8px;
        background: var(
          --text-gradient,
          linear-gradient(180deg, #8544b4 0%, #4b0082 100%)
        );
        color: #fff;
        font-size: 12px;
        font-weight: 500;
        padding: 8px;
        width: 100px;
      }
      .title {
        font-size: 14px;
        font-weight: 500;
      }
      .description {
        font-size: 12px;
      }
      .pricing {
        font-size: 32px;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        .text {
          color: rgba(51, 51, 51, 0.7);
          font-size: 12px;
          font-weight: 500;
          text-align: left;
        }
      }
      .cta {
        border-radius: 8px;
        text-transform: none;
        font-size: 16px;
        background: #590895;
        font-weight: 700;
        padding: 8px 24px;
        box-shadow: 0px 0px 12px 0px rgba(75, 0, 130, 0.6);
        &:hover {
          // background: linear-gradient(180deg, #8544b4 0%, #4b0082 100%);
          // background: #4b0082;
          box-shadow: 0px 0px 8px 0px rgba(75, 0, 130, 0.9);
        }
      }
      .points {
        display: flex;
        flex-direction: column;
        text-align: left;
        gap: 16px;
        .point {
          display: flex;
          gap: 8px;
          font-size: 12px;
          align-items: center;
          .check {
            font-size: 14px;
            color: #4b0082;
          }
        }
      }
    }
    .active {
      box-shadow: 0px 0px 8px 0px rgba(161, 103, 205, 0.7);
      border: 1px solid #4b0082;
    }
  }
}

.pricingDarkSmall {
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .title {
    font-size: 32px;
    font-weight: 900;
  }
  .description {
    font-size: 14px;
    font-weight: 500;
  }
  .tiles {
    display: flex;
    gap: 16px;
    flex-direction: column;
    text-align: center;
    .tile {
      padding: 24px;
      border-radius: 12px;
      background: linear-gradient(180deg, #1d0033 0%, rgba(24, 0, 42, 0) 100%);
      display: flex;
      flex-direction: column;
      gap: 16px;
      &:hover {
        box-shadow: 0px 0px 8px 0px rgba(161, 103, 205, 0.7);
      }
      .popular {
        margin: -38px auto auto auto;
        border-radius: 8px;
        background: var(
          --text-gradient,
          linear-gradient(180deg, #8544b4 0%, #4b0082 100%)
        );
        color: #fff;
        font-size: 12px;
        font-weight: 500;
        padding: 8px;
        width: 100px;
      }
      .title {
        font-size: 14px;
        font-weight: 500;
      }
      .description {
        font-size: 12px;
      }
      .pricing {
        font-size: 32px;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        .text {
          color: rgba(51, 51, 51, 0.7);
          font-size: 12px;
          font-weight: 500;
          text-align: left;
        }
      }
      .cta {
        border-radius: 8px;
        text-transform: none;
        font-size: 16px;
        background: #590895;
        font-weight: 700;
        padding: 8px 24px;
        box-shadow: 0px 0px 12px 0px rgba(75, 0, 130, 0.6);
        &:hover {
          // background: linear-gradient(180deg, #8544b4 0%, #4b0082 100%);
          // background: #4b0082;
          box-shadow: 0px 0px 8px 0px rgba(75, 0, 130, 0.9);
        }
      }
      .points {
        display: flex;
        flex-direction: column;
        text-align: left;
        gap: 16px;
        .point {
          display: flex;
          gap: 8px;
          font-size: 12px;
          align-items: center;
          .check {
            font-size: 14px;
            color: #4b0082;
          }
        }
      }
    }
    .active {
      box-shadow: 0px 0px 8px 0px rgba(161, 103, 205, 0.7);
      border: 1px solid #4b0082;
    }
  }
}
